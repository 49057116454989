@tailwind base;

@tailwind components;

@tailwind utilities;

// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

a {
    text-decoration: none;
}

.bg-brandprimary {
    // --bg-opacity: 1;
    background-color: #18d1cc;
    // background-color: rgba(26, 105, 183, var(--bg-opacity));
}

.lg\:border-brandprimary {
    border-color: #18d1cc;
}

.font-semibold {
    font-weight: 600;
}

.text-7xl {
    font-size: 2.5rem;
}
